.home {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    .assistant {
        margin-top: auto;
        margin-bottom: auto;
    }
}