.app {
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #333333;
    margin-bottom: 15px;

    .logo {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }

    .nav-links {
      margin-left: auto;
      a {
        font-size: 16px;
        line-height: 20px;
        position: relative;
      }

      a + a {
        margin-left: 20px;
        &::before {
          content: "";
          border-left: 1px solid #2E3641;
          position: absolute;
          left: -10px;
          top: 2px;
          bottom: 0;
        }
      }
    }
  }

  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
