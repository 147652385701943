.input-form {
  width: 300px;
  .buttons {
    display: inline-flexbox;
  }

  h2 {
    margin-bottom: 12px;
  }

  input {
    width: 100%;
    height: 38px;
    border-radius: 6px;
    background-color: #F7F7F7;
    padding: 0 15px;
    border: none;
    outline: none;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    padding-left: 10px;
  }

  .buttons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    button {
      height: 38px;
      padding: 0 20px;
      border: none;
      outline: none;
      border-radius: 6px;
      margin-left: 12px;
    }

    .btn-send {
      background-color: #5D646F;
      color: white;
    }

    btn-cancel {
      background-color: #F7F7F7;
      color: #5D646F;
    }
  }
}