@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2E3641;
  background-color: #FEFDF9;
}

h1, h2, h3 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: inherit;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

a {
  color: #989690;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
  flex-shrink: 0;
}

button {
  cursor: pointer;
}

.custom-dialog-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($color: grey, $amount: 0.5);
  overflow: auto;
  display: flex;
  padding: 0 10px;
}

.custom-dialog {
  margin:auto;
  flex-shrink: 0;
  outline: none;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(37, 40, 43, 0.14);
  position: relative;
  max-width: 100%;

  .close-modal-button {
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .btn-close-custom-dialog {
    position: absolute;
    padding: 0;
    width: 22px;
    height: 22px;
    top: 16px;
    right: 16px;
  }
}
