.assistant {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 30px;
        font-size: 20px;
    }

    .btn-mic {
        width: 100px;
        height: 100px;
        background: url('../../images/mic.svg') center center no-repeat;
        background-size: contain;
        border: none;
        outline: none;
        position: relative;
        &[disabled] {
            opacity: 0.3;
        }
    }

    .volume {
        margin-top: 20px;
        width: 200px;
        height: 20px;
        background-color: #F0F0F0;
        visibility: hidden;
        .bar {
            height: 100%;
            background-color: yellowgreen;
        }
    }


    &.running {
        .volume {
            visibility: visible;
        }

        .btn-mic {
            &::after {
                content: "";
                width: 20px;
                height: 20px;
                border: 3px solid white;
                background-color: red;
                left: 15px;
                top: 15px;
                position: absolute;
                transform: translate(-50%, - 50%);
                border-radius: 50%;
                animation-name: assistant-animation;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
            }
        }
    }

    .email-block, .phone-block {
        margin-top: 20px;
    }
}

@keyframes assistant-animation {
    0% {
        width: 6px;
        height: 6px;
    }

    50% {
        width: 20px;
        height: 20px;
    }

    100% {
        width: 6px;
        height: 6px;
    }
  }